<template>
  <div class="panel" :class="panelClass">
    <div
      v-if="title || $slots['title']"
      class="panel__header flex flex-start flex-top flex-wrap gap-m">
      <slot name="title" :panel="this">
        <icon v-if="icon" :icon="icon" size="1.8"></icon>
        <h3 class="panel__title">{{ translate(title) }}</h3>
      </slot>
    </div>
    <div class="panel__body">
      <slot name="default" :panel="this"></slot>
    </div>

    <div v-if="$slots['footer']" class="panel__footer">
      <slot name="footer" :panel="this"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: {
      type: [String],
      default: "default",
    },
    // standard/slim
    title: {
      type: [String, Boolean],
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    finalTheme() {
      return this.theme || "default";
    },
    finalBadgeTheme() {
      return this.badgeTheme || "default";
    },
    finalBadgeSize() {
      return this.badgeSize || "default";
    },
    panelClass() {
      let result = ["panel"];
      result.push("panel--" + this.finalTheme);

      if (this.fullWidth) {
        result.push("width-expand");
      }
      return result;
    },
  },
  methods: {},
};
</script>

<style scoped lang="scss"></style>
